
@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Matter Regular";
  src: url("assets/Matter-Regular.ttf");
}

@font-face {
  font-family: "Matter Semi Bold";
  src: url("assets/Matter-SemiBold.ttf");
}

/* Defining  Tailwind css */
@layer utilities {

  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.main-container a {
  color: #0000EE !important;
}

body {
  line-height: 26px;
}

/* SCROLLBAR CSS*/
html {
  scroll-behavior: smooth;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.hide-scrollbar {
  -ms-overflow-style: none !important;
  /* Internet Explorer 10+ */
  scrollbar-width: 0 !important;
  /* Firefox */
}

.hide-scrollbar::-webkit-scrollbar {
  width: 0 !important;
  /* Safari and Chrome */
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.bp-fade-in {
  animation: fadeIn 0.5s ease-in;
}


/* Animation for entry (Bottom-right to Top-left) */
@keyframes slide-in {
  0% {
    transform: translate(100vw, 100vh);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(150px);
    transform: translateY(150px);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}


.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.5s both !important;
  animation: fade-in-bottom 0.5s both !important;
}
